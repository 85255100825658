import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"

export const SettingsPageTemplate = props => {
  
  const {
    title,    
  } = props

  return (
    <>
      <Helmet title={title}>
        <meta name="description" content={title} />
      </Helmet>

      <section>
        <div className="container">
          <div className="row">
            <div className="columns col-xs-12">
              <h2>{title}</h2>
              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

SettingsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,  
}

const SettingsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <SettingsPageTemplate
      title={post.frontmatter.title}
    />
  )
}

SettingsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SettingsPage

export const pageQuery = graphql`
  query SettingsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
